import React from "react";
import styled from "@emotion/styled";
import { Link, graphql } from "gatsby";
import { withUnpublishedPreview } from "gatsby-source-prismic";
import Layout from "../components/layout/index";
import SEO from "../components/seo";
import PostBlog from "../templates/postBlog";
import PostApp from "../templates/postApp";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const headingStyles = {
  marginTop: 0,
  marginBottom: "1em",
  maxWidth: 320,
};

const paragraphStyles = {
  marginBottom: 48,
};
const LinkMenu = styled(Link)`
  color: #000;
  text-decoration: none;
  color: #868686;

  ::before {
    content: "← ";
  }
  :hover {
    text-decoration: underline;
  }
`;

// markup
const NotFoundPage = ({ location: { pathname } }) => {
  return (
    <Layout>
      <SEO title="404 not found" pathname={pathname} />
      <main style={pageStyles}>
        <title>Not found</title>
        <h1 style={headingStyles}>Page not found</h1>
        <div style={paragraphStyles}>
          We couldn't find the page you were looking for. This is either
          because:
          <br />
          <div
            style={{
              fontWeight: 600,
              margin: "0.5em 0em",
            }}
          >
            <ul>
              <li>
                There is an error in the URL entered into your web browser.
                Please check the URL and try again.
              </li>
              <li>
                You can return to our homepage by clicking here, or you can try
                searching for the content you are seeking by clicking here.
              </li>
            </ul>
          </div>
          {/* <br /> */}
          {/* <LinkMenu to="/">Go home</LinkMenu>. */}
        </div>
      </main>
    </Layout>
  );
};

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    "post-blog": PostBlog,
    "post-app": PostApp,
    prismicPostBlog: PostBlog,
    prismicPostApp: PostApp,
  },
});

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
